import { Button, Space, theme, Typography, Layout } from 'antd';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SocketContext from '../store/SocketContext';
import { Me, userActions } from '../store/slice/usersSlice';
import Loader from './loader/Loader';
import LoginPage from './LoginPage/LoginPage';
import AdminPanel from './AdminPanel/AdminPanel';
import { dashboardActions } from '../store/slice/dashboardSlice';
import { dealerActions } from '../store/slice/dealerSlice';

const AdminLayout = () => {
    const { useToken } = theme;
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);

    useEffect(() => {
        if (socket) {
            socket.on("connect", () => {
                socket.emit("join", 'Manager');
            });
            socket.emit("checkIsLoggedin")
            socket.on("userLoggedIn", (data) => {
                const authToken = "Bearer "+data.accessToken;
                socket.auth.token = authToken;
                socket.disconnect();
                
                // Connect to the server with the updated auth token
                socket.connect({
                    auth: {
                        token: authToken
                    }
                });
                dispatch(userActions.updateUser(data))  
            });
            socket.on("userUpdate", (data) => {
                dispatch(userActions.updateUser(data))
            });
            socket.on("setTables", (data) => {
                dispatch(dashboardActions.setTablesList(data))
            });
            socket.on("setDealers", (data) => {
                dispatch(dealerActions.setDealers(data));
            });
        }
    }, [socket, dispatch])

    useEffect(() => {
        dispatch(Me())
    }, [])


    const { token } = useToken();
    const userData = useSelector(state =>  state.user);

    if (!userData.loaded) {
        return (<Layout>
            <Loader />
        </Layout>)
    }
    if (!userData.isLoggedin) {
        return <LoginPage />
    }
    return (
        <AdminPanel />
    )
}

export default AdminLayout;